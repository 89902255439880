<template>
  <v-row class="mainContainer">
    <v-col class="sectionLeft" cols="12" sm="12" md="9"></v-col>

    <v-col class="sectionRight" cols="12" sm="12" md="3">
      <div class="boxLogo">
        <img src="../../assets/siscovlogo1.svg" alt="" />
        
      </div>

      <ErrorLogin :resource="errorLogin" />

      <v-form
        @submit.prevent="login"
        v-model="valid"
        lazy-validation
        ref="form"
      >
        <v-text-field
          class="rounded-lg"
          label="CPF"
          placeholder="Digite seu CPF"
          outlined
          dense
          v-model="cpf"
          :rules="cpfRules"
          required
          v-mask="'###.###.###-##'"
        ></v-text-field>

        <v-text-field
          class="rounded-lg"
          label="Senha"
          placeholder="Digite sua senha"
          outlined
          dense
          :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPass ? 'text' : 'password'"
          @click:append="showPass = !showPass"
          v-model="password"
          :rules="passRules"
          required
        ></v-text-field>

        <div class="captchaGrid">
          <img :src="img" alt="" />

          <div class="space"></div>

          <v-text-field
            class="rounded-lg"
            label="Captcha"
            placeholder="Digite o captcha"
            outlined
            dense
            v-model="captcha"
            :rules="captchaRules"
            v-mask="'####'"
            required
          ></v-text-field>

          <div class="space"></div>

          <v-btn
            class="rounded-lg mb-5"
            color="black"
            out
            elevation="0"
            height="40px"
            @click="initialize()"
          >
            <v-icon dense color="white">mdi-refresh</v-icon>
          </v-btn>
        </div>

        <v-btn
          type="submit"
          class="rounded-lg mb-5 text-none"
          block
          color="primary"
          elevation="1"
          large
          :loading="carregamento"
          :disabled="desativado"
          >{{ btnStatus }}</v-btn
        >
      </v-form>
      <ResetPass :resource="btnName"/>
    </v-col>
  </v-row>
</template>
<script>
import axios from "axios";
import config from "../../config/config";
import ErrorLogin from "../alerts/ErrorLogin-comp.vue";
import ResetPass from "./NewPassword-comp.vue";

export default {
  data() {
    return {
      valid: true,
      showPass: false,
      cpf: "",
      cpfRules: [
        (v) => !!v || "O CPF é obrigatório",
        (v) => (v && v.length <= 14) || "Você excedeu o tamanho máximo.",
      ],
      password: "",
      passRules: [
        (v) => !!v || "A senha é obrigatória",
        (v) => (v && v.length <= 16) || "Você excedeu o tamanho máximo.",
      ],
      captcha: "",
      captchaRules: [
        (v) => !!v || "O captcha é obrigatório",
        (v) => (v && v.length <= 4) || "Você excedeu o tamanho máximo.",
      ],
      key: "",
      img: "",
      btnStatus: "Entrar",
      carregamento: false,
      desativado: false,
      errorLogin: [],
      btnName: 'ESQUECI MINHA SENHA'
    };
  },

  components: {
    ErrorLogin,
    ResetPass,
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      axios.get(`${config.captchaURL}`).then((response) => {
        this.key = response.data.key;
        this.img = response.data.img;
      });
    },

    login() {
      if (this.$refs.form.validate() == true) {
        const newCPF = this.cpf.replace(/[^a-zA-Z0-9]/g, "");
        const dados = new Object();
        dados.cpf = newCPF;
        dados.password = this.password;
        dados.captcha = this.captcha;
        dados.key = this.key;
        dados.device_name = "sesab";

        axios
          .post(`${config.apiURL}/login`, dados)
          .then((response) => {
            this.carregamento = true;
            this.desativado = true;

            setTimeout(() => {
              this.carregamento = false;
              this.desativado = false;
              this.btnStatus = response.data.message;

              localStorage.setItem("token", response.data.token);
              setTimeout(() => {
                this.$router.push({ path: "/dashboard" });
              }, 1000);
            }, 1500);
          })

          .catch((error) => {
            this.errorLogin = error.response.data.details;
            this.captcha = "";
            this.initialize();
          });
      }
    },
  },
};
</script>
<style scoped>
* {
  font-family: "Poppins";
}

.mainContainer {
  margin: 0;
  height: 100vh;
  background-color: #d9afd9;
  background-image: linear-gradient(0deg, #00d9ff 0%, #1900ff 100%);
}

.sectionLeft {
  background: #ffffff;
  background-image: url(../../assets/bg_siscov_v1.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.sectionRight {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 6px;

  padding: 35px;
  background: rgb(255, 255, 255);
}

.sectionRight > .boxLogo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
  margin-bottom: 30px;
}

.boxLogo > h3 {
  font-family: "Archivo Black", sans-serif;
  font-size: 24px;
  line-height: 26px;
  color: rgb(0, 0, 0);
}

.boxLogo > span {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  color: rgb(0, 0, 0);
}

.captchaGrid {
  display: flex;
}

.captchaGrid > img {
  height: 40px;
  width: 100px;

  border: 1px solid transparent;
  border-radius: 8px;
}

.captchaGrid > .space {
  width: 10px;
}

/* Responsivo */
@media only screen and (max-width: 1380px) {
  .sectionLeft {
    flex: 0 0 70%;
    max-width: 70%;
  }

  .sectionRight {
    flex: 0 0 30%;
    max-width: 30%;
  }
}

@media only screen and (max-width: 960px) {
  .mainContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 40px;
  }
  .sectionLeft {
    display: none;
  }

  .sectionRight {
    min-width: 400px;
    border-radius: 10px;
  }
}
</style>
